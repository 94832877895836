<template>
    <div
        class="flex flex-col items-center justify-between w-screen fixed h-full overflow-hidden custom-height"
        style="background-color: #eef0f2"
    >
        <Header />
        <div
            v-if="isLoading"
            class="h-full w-full flex justify-center items-center"
        >
            <svg
                class="w-10 h-10 animate-spin mt-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </div>
        <div
            v-else
            class="mt-5 sm:mt-10 mb-0 sm:mb-10 w-full max-w-5xl overflow-hidden relative h-full bg-survey_container"
            :class="[
                activeIndex !== 0 && activeIndex !== steps?.length - 1
                    ? 'pb-16 sm:pb-24'
                    : 'pb-0',
            ]"
        >
            <swiper
                @swiper="setSwiper"
                :modules="[Controller]"
                :touchMoveStopPropagation="false"
                :simulateTouch="false"
                :preventInteractionOnTransition="true"
                :touchRatio="0"
                :slideToClickedSlide="true"
                :controller="{ control: swiper }"
                :slides-per-view="1"
                :spaceBetween="10"
                :initialSlide="activeIndex && parseInt(activeIndex)"
                class="h-full overflow-y-scroll"
            >
                <swiper-slide
                    v-for="(step, index) in steps"
                    :key="index"
                    class="h-full overflow-y-scroll p-5 sm:p-20"
                >
                    <div class="relative h-full">
                        <div v-if="index === 0">
                            <div
                                class="flex space-x-6 justify-start pb-3 relative items-center"
                            >
                                <Icon
                                    name="LocationMarkerIcon"
                                    size="10"
                                    color="lightblue_icon"
                                    is-outline
                                ></Icon>
                                <Icon
                                    name="MapIcon"
                                    size="10"
                                    color="indigo-600"
                                    is-outline
                                ></Icon>
                                <div
                                    class="border-t border-dotted w-8 border-gray-300 absolute left-2.5"
                                />
                            </div>
                            <Text
                                :content="step?.title"
                                size="4xl"
                                color="graytext"
                                weight="bold"
                                align="left"
                                custom-class="leading-snug pb-3"
                            ></Text>
                            <Text
                                :content="step?.description"
                                size="base"
                                color="graytext"
                                weight="medium"
                                align="left"
                            ></Text>
                            <Button
                                variant="default"
                                size="md"
                                content="Get Started"
                                is-outline-icon
                                is-show-text
                                @click="() => onNext()"
                                custom-class="bg-dark_indigo text-white mt-12 z-10"
                            />
                        </div>
                        <div v-else-if="step?.empty" class="pb-5">
                            <div
                                class="flex space-x-4 justify-start items-center relative pb-3"
                            >
                                <Icon
                                    name="ThumbUpIcon"
                                    size="10"
                                    color="lightblue_icon"
                                    is-outline
                                ></Icon>
                                <Icon
                                    name="HeartIcon"
                                    size="10"
                                    color="red_icon"
                                    is-outline
                                ></Icon>
                                <div
                                    class="border-t border-dotted w-6 border-gray-300 absolute left-5"
                                />
                            </div>
                            <Text
                                :content="getFinalStep()?.title"
                                size="4xl"
                                color="graytext"
                                weight="bold"
                                align="left"
                                custom-class="leading-snug pb-3"
                            ></Text>
                            <Text
                                v-html="getFinalStep()?.description"
                                size="base"
                                color="graytext"
                                weight="medium"
                                align="left"
                            ></Text>
                            <div class="pt-4 w-full">
                                <InputWithCopy
                                    v-model:value="step.url"
                                    disabled
                                ></InputWithCopy>
                            </div>
                            <div
                                v-if="steps?.[activeIndex]?.empty"
                                class="flex sm:hidden w-full bg-gray-100 max-w-5xl items-start md:h-60 border-t rounded mb-5 py-5 sm:p-0 mt-5"
                            >
                                <div
                                    class="flex flex-col p-0 sm:p-6 justify-center items-start w-full md:w-3/5 md:h-full"
                                >
                                    <Text
                                        :content="
                                            steps?.[activeIndex]?.empty
                                                ?.description
                                        "
                                        size="lg"
                                        color="graytext"
                                        weight="bold"
                                        align="left"
                                        custom-class="leading-snug pb-3 sm:text-xl"
                                    ></Text>
                                    <div class="mt-2">
                                        <Button
                                            :content="
                                                steps?.[activeIndex]?.empty
                                                    ?.btnText
                                            "
                                            size="md"
                                            variant="default"
                                            custom-class="bg-dark_indigo text-white justify-start"
                                            :click="onRegister"
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="pb-10">
                            <Text
                                :content="step?.title"
                                size="4xl"
                                color="graytext"
                                weight="bold"
                                align="left"
                                custom-class="leading-snug pb-3"
                            ></Text>
                            <Text
                                v-if="step?.description"
                                size="lg"
                                weight=""
                                color="gray-500"
                                v-html="step?.description"
                                custom-class="mt-4"
                            ></Text>
                            <div class="space-y-20 mt-5 p-1">
                                <div
                                    v-for="(item, i) in step?.items?.filter(
                                        (s) => s?.category !== 'Heading'
                                    )"
                                    :key="i"
                                >
                                    <RadioGroup
                                        v-if="
                                            item?.type === 'RADIO' &&
                                            getSurveyCondition(step, item)
                                        "
                                        :items="item?.options"
                                        v-model:value="item.value"
                                        :name="
                                            slugify(
                                                (item && item.label) ||
                                                    `${index}_${i}`
                                            )
                                        "
                                        :label="item && item.label"
                                        isLarge
                                    />
                                    <div class="w-full md:w-1/2">
                                        <Input
                                            v-if="
                                                (item.type === 'NUMBER' ||
                                                    item.type === 'TEXT' ||
                                                    isShowEmail(item)) &&
                                                getSurveyCondition(step, item)
                                            "
                                            v-model:value="item.value"
                                            :label="item?.label"
                                            :type="item?.type?.toLowerCase()"
                                            :placeholder="item.placeholder"
                                            :input-mask="item.mask"
                                            isLarge
                                        />
                                    </div>
                                    <TextArea
                                        v-if="
                                            item.type === 'TEXTAREA' &&
                                            getSurveyCondition(step, item)
                                        "
                                        v-model:value="item.value"
                                        :label="item.label"
                                        :placeholder="item.placeholder"
                                        isLarge
                                    />
                                    <AutoComplete
                                        v-if="
                                            item.type === 'AUTOCOMPLETE' &&
                                            getSurveyCondition(step, item)
                                        "
                                        :placeholder="item.placeholder"
                                        :label="item.label"
                                        v-model:selected-value="item.value"
                                        :listData="item.options || []"
                                        class="mt-5"
                                        isLarge
                                    />
                                    <MultiSelectDropdown
                                        v-if="
                                            item.type === 'MULTISELECT' &&
                                            getSurveyCondition(step, item)
                                        "
                                        isObj
                                        :options="state.google.placeResults"
                                        v-model:selected="item.value"
                                        :label="item.label"
                                        input-class="bg-survey_container"
                                        isNotAdd
                                        :description="item.helpText"
                                        isLocation
                                        @onSearch="searchGooglePlaces"
                                        isLarge
                                    />
                                    <div
                                        v-if="
                                            item.type === 'CHECKBOX' &&
                                            !item.isAdd &&
                                            getSurveyCondition(step, item)
                                        "
                                    >
                                        <Text
                                            size="md"
                                            weight=""
                                            color="gray-700"
                                            :content="item.label"
                                        />
                                        <Text
                                            size="xs"
                                            color="gray-700"
                                            custom-class="mt-1 italic"
                                            :content="item.helpText"
                                        />
                                        <CheckboxGroup
                                            v-model:selected="item.value"
                                            :items="item.options"
                                            class="mt-2"
                                        />
                                    </div>
                                    <div
                                        v-else-if="
                                            item.type === 'CHECKBOX' &&
                                            item.category?.includes(
                                                'Problems'
                                            ) &&
                                            steps?.[activeIndex]?.items?.find(
                                                (i) =>
                                                    i?.category?.includes(
                                                        'Experience'
                                                    )
                                            )?.value?.[1] <= 0 &&
                                            item.isAdd &&
                                            getSurveyCondition(step, item)
                                        "
                                    >
                                        <Text
                                            size="md"
                                            weight=""
                                            color="gray-700"
                                            :content="item.label"
                                        />
                                        <Text
                                            size="xs"
                                            color="gray-700"
                                            custom-class="mt-1 italic"
                                            :content="item.helpText"
                                        />
                                        <CheckboxGroup
                                            v-model:selected="item.value"
                                            :items="item.options"
                                            class="mt-2"
                                        />
                                        <div class="mt-5 w-full md:w-1/2">
                                            <Dropdown
                                                :placeholder="'Add your own'"
                                                label=""
                                                :items="
                                                    economies?.filter(
                                                        (e) =>
                                                            e?.name &&
                                                            !item.options?.find(
                                                                (o) =>
                                                                    o?.answer ===
                                                                    e?.name
                                                            )
                                                    )
                                                "
                                                :onChange="
                                                    (val) =>
                                                        handleProblem(val, item)
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="
                                            item.type === 'CHECKBOX' &&
                                            !item.category?.includes(
                                                'Problems'
                                            ) &&
                                            item.isAdd &&
                                            getSurveyCondition(step, item)
                                        "
                                    >
                                        <Text
                                            size="md"
                                            weight=""
                                            color="gray-700"
                                            :content="item.label"
                                        />
                                        <Text
                                            size="xs"
                                            color="gray-700"
                                            custom-class="mt-1 italic mb-4"
                                            :content="item.helpText"
                                        />
                                        <CheckboxListWithInput
                                            v-model:selected="item.value"
                                            placeholder="Add your own"
                                            :items="item.options"
                                            class="mt-2"
                                            :onAdd="
                                                (text) => {
                                                    item.options?.push({
                                                        answer: text,
                                                    });
                                                    item.value.push({
                                                        answer: text,
                                                    });
                                                }
                                            "
                                        />
                                    </div>

                                    <div
                                        v-if="
                                            item.type === 'BOOLEAN' &&
                                            getSurveyCondition(step, item)
                                        "
                                    >
                                        <RadioGroup
                                            :items="[
                                                { id: 0, title: 'Yes' },
                                                { id: 1, title: 'No' },
                                            ]"
                                            v-model:value="item.value"
                                            :name="
                                                slugify(
                                                    (item && item.label) ||
                                                        `${index}_${i}`
                                                )
                                            "
                                            :label="item?.label"
                                            isLarge
                                        />
                                    </div>
                                    <div
                                        v-if="
                                            item.type === 'SLIDER' &&
                                            getSurveyCondition(step, item)
                                        "
                                        class="pb-5"
                                    >
                                        <Text
                                            size="md"
                                            weight=""
                                            color="gray-700"
                                            :content="item.label"
                                        />
                                        <Text
                                            v-if="item.helpText"
                                            size="xs"
                                            color="gray-700"
                                            custom-class="mt-1 italic"
                                            :content="item.helpText"
                                        />
                                        <Slider
                                            v-model:selected="item.value"
                                            @onDragged="
                                                () => (item.isDragged = true)
                                            "
                                            :marks="item.mark"
                                            :min="-5"
                                            :max="5"
                                        />
                                    </div>
                                    <Input
                                        v-model:value="item.inputValue"
                                        v-if="
                                            item?.value?.name ===
                                                'Self describe' ||
                                            (isArray(item?.value) &&
                                                item?.value?.find(
                                                    (v) =>
                                                        v?.name ===
                                                            'Self describe' ||
                                                        v === 'Self describe'
                                                ))
                                        "
                                        placeholder="Describe how you identify yourself?"
                                        custom-class="mt-4"
                                        isLarge
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

            <Footer
                @onNext="() => onNext()"
                @onBack="() => onBack()"
                :isLoading="isSaving"
                :isShowBtn="!steps?.[activeIndex]?.empty"
                :isDemographic="steps?.[activeIndex]?.type === 'DEMOGRAPHIC'"
                :steps="steps?.length"
                :currentStep="activeIndex"
                :isValid="isValid"
            >
            </Footer>
        </div>
        <div
            v-if="steps?.[activeIndex]?.empty"
            class="hidden sm:flex w-full bg-gray-100 max-w-5xl items-start md:h-60 border-t rounded mb-5 p-5 sm:p-0 -mt-5"
        >
            <img
                src="https://dscvry-live-media.s3.amazonaws.com/success-img.png"
                class="w-2/5 h-full hidden md:block object-cover rounded-l"
            />
            <div
                class="flex flex-col p-0 sm:p-6 justify-center items-start w-full md:w-3/5 md:h-full"
            >
                <Text
                    :content="steps?.[activeIndex]?.empty?.description"
                    size="xl"
                    color="graytext"
                    weight="bold"
                    align="left"
                    custom-class="leading-snug pb-3"
                ></Text>
                <div class="mt-6">
                    <Button
                        :content="steps?.[activeIndex]?.empty?.btnText"
                        size="md"
                        variant="default"
                        custom-class="bg-dark_indigo text-white justify-start"
                        :click="onRegister"
                    ></Button>
                </div>
            </div>
        </div>
        <div
            v-if="activeIndex === 0 || activeIndex === steps?.length - 1"
            class="hidden sm:flex justify-center items-center py-2"
        >
            <Text
                size="xs"
                color="black"
                align="center"
                weight="semibold"
                content="Powered by DSCVRY"
            />
        </div>
    </div>
</template>
<script>
import Text from "@/components/atoms/Text/Text";
import Icon from "@/components/atoms/Icons/Icons";
import Button from "@/components/atoms/Button/Button";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Controller, Virtual } from "swiper";
import "swiper/swiper.min.css";
import { ref } from "vue";
import Checkbox from "@/components/molecules/Inputs/Checkbox/Checkbox.vue";
import Image from "@/components/atoms/Image/Image.vue";
import Input from "@/components/molecules/Inputs/Component/Component.vue";
import TextArea from "../components/molecules/Textareas/Simple/Simple.vue";
import RadioGroup from "@/components/molecules/RadioGroup/Simple/Simple";
import CheckboxGroup from "@/components/molecules/Checkboxes/SimpleList/SimpleList";
import AutoComplete from "@/components/molecules/AutoComplete/AutoComplete";
import EmptyState from "@/components/organisms/EmptyState/Simple/Simple.vue";
import MultiSelectDropdown from "../components/molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue";
import { c } from "../components/constants.js";
import slugify from "slugify";
import { json } from "overmind";
import { isArray, lowerFirst, upperFirst } from "lodash";
import useClipboard from "vue-clipboard3";
import Slider from "@/components/molecules/RangeSlider/RangeSlider.vue";
import InputWithCopy from "@/components/molecules/Inputs/InputWithCopyIcon/InputWithCopyIcon.vue";
import Header from "@/components/organisms/Headers/SimpleWithLogo/SimpleWithLogo.vue";
import Footer from "@/components/organisms/Footer/SimpleCentered/SimpleCentered.vue";
import CheckboxListWithInput from "@/components/molecules/Checkboxes/ListWithInput/ListWithInput.vue";
import Dropdown from "@/components/molecules/Selects/Component/Component.vue";
import { _ } from "vue-underscore";
import $ from "jquery";

export default {
    components: {
        Text,
        Button,
        Swiper,
        SwiperSlide,
        Checkbox,
        Image,
        Input,
        RadioGroup,
        CheckboxGroup,
        AutoComplete,
        EmptyState,
        MultiSelectDropdown,
        TextArea,
        Icon,
        Slider,
        InputWithCopy,
        Header,
        Footer,
        CheckboxListWithInput,
        Dropdown,
    },
    watch: {
        steps: {
            handler(val) {
                this.isValid = this.onValidate();
            },
            deep: true,
        },
        activeIndex: function (val) {
            this.isValid = false;
            this.isValid = this.onValidate();
        },
    },
    methods: {
        handleProblem(val, item) {
            if (val?.name) {
                item.options.push({
                    answer: val?.name,
                    title: val?.name,
                    problem: val?.id,
                    // id: val?.id,
                });
                item.value.push({
                    answer: val?.name,
                    title: val?.name,
                    problem: val?.id,
                    // id: val?.id,
                });
            }
        },
        getSurveyCondition(step, item) {
            if (step.type === "SURVEY") {
                if (
                    item.type === "BOOLEAN" ||
                    item.category?.includes("Desire")
                ) {
                    return true;
                } else {
                    return (
                        step.items?.find((i) => i?.type === "BOOLEAN")?.value
                            ?.title !== "No"
                    );
                }
            } else {
                return true;
            }
        },
        async onCopyUrl(url) {
            const { toClipboard } = useClipboard();
            try {
                await toClipboard(url);
                this.actions.alert.showSuccess({
                    message: "Copied the url to your clipboard",
                });
            } catch (e) {
                console.error(e);
            }
        },
        onRegister() {
            const demographicSection = this.steps?.find(
                (s) => s.type === "DEMOGRAPHIC"
            );
            if (
                demographicSection?.items?.find(
                    (item) =>
                        item?.label ===
                        "Would you be open to talking more about this topic?"
                )?.value?.title === "Yes"
            ) {
                this.$router.push({
                    name: "Register",
                    query: { type: "founder" },
                });
            } else {
                this.$router.push({
                    name: "Register",
                    query: {
                        type: "founder",
                        userId: this.user?.id,
                        email: this.user?.email,
                    },
                });
            }
        },
        isShowEmail(item) {
            return (
                item?.type === "EMAIL" &&
                this.steps[this.activeIndex]?.items?.find(
                    (item) =>
                        item?.label ===
                        "Would you be open to talking more about this topic?"
                )?.value?.title === "Yes"
            );
        },
        onBack() {
            this.activeIndex =
                this.previousSteps[this.previousSteps?.length - 1];
            this.swiper.slideTo(this.activeIndex);
            this.previousSteps.pop();
        },
        onValidate() {
            const step = this.steps[this.activeIndex];
            if (this.activeIndex === 0) {
                return true;
            } else if (step.type === "TAG") {
                return !step.items.find((item) => !item.value?.title);
            } else if (step.type === "SURVEY") {
                if (step.items?.[0]?.value?.title === "Yes") {
                    const items = [];
                    step.items?.map((item) => {
                        if (
                            item.type === "CHECKBOX" &&
                            item?.value?.length > 0
                        ) {
                            items.push(item);
                        } else if (item.type === "SLIDER" && item.isDragged) {
                            items.push(item);
                        } else if (item.type === "NUMBER" && item.value) {
                            items.push(item);
                        } else if (
                            item.type === "BOOLEAN" &&
                            item.value?.title
                        ) {
                            items.push(item);
                        }
                    });
                    console.log(items, "items");
                    return step.items?.find((i) =>
                        i?.category?.includes("Experience")
                    )?.value?.[1] <= 0
                        ? items.length === step.items.length
                        : items.length >= step.items?.length - 1;
                } else if (step.items?.[0]?.value?.title === "No") {
                    return step.items?.find((item) =>
                        item?.category?.includes("Desire")
                    )?.isDragged;
                } else {
                    return false;
                }
            } else if (step.type === "DEMOGRAPHIC") {
                console.log(step.items, "items");
                const items = [];
                step.items.map((item) => {
                    if (item.type === "RADIO" && item?.value?.title) {
                        items.push(item);
                    } else if (
                        (item.type === "CHECKBOX" ||
                            item.type === "MULTISELECT") &&
                        item?.value?.length > 0
                    ) {
                        items.push(item);
                    }
                });
                console.log(items, "items");
                return this.$route.query?.contactId
                    ? items.length === step.items?.length
                    : items.length === step.items.length - 1;
            } else {
                return true;
            }
        },
        async onNext() {
            console.log(this.steps, "steps");
            this.previousSteps.push(this.activeIndex);
            if (this.activeIndex === 1 && !this.$route.query.contactId) {
                const items = this.steps[this.activeIndex].items?.filter(
                    (item) => item?.value?.title === "Yes"
                );
                if (
                    items?.length !==
                    this.steps[this.activeIndex]?.items?.length
                ) {
                    this.activeIndex = this.steps?.length - 1;
                    this.swiper.slideTo(this.activeIndex);
                } else {
                    this.swiper.slideTo(this.activeIndex + 1);
                    this.activeIndex = this.activeIndex + 1;
                }
            } else if (this.steps[this.activeIndex].type === "DEMOGRAPHIC") {
                if (!this.$route.query?.category) {
                    await this.onSaveStepData();
                }
                this.swiper.slideTo(this.activeIndex + 1);
                this.activeIndex = this.activeIndex + 1;
            } else {
                if (!this.$route.query?.category) {
                    await this.onSaveStepData();
                }
                this.swiper.slideTo(this.activeIndex + 1);
                this.activeIndex = this.activeIndex + 1;
            }
        },
        async searchGooglePlaces(val) {
            try {
                if (val) {
                    await this.actions.google.searchGooglePlaces({
                        keyword: val,
                        types: ["political"],
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
        async onSaveStepData() {
            if (this.isSaving) return false;
            const step = this.steps[this.activeIndex];
            console.log(step, "step");
            this.isSaving = true;
            try {
                if (step?.type === "SURVEY") {
                    const surveyStep = step;
                    if (surveyStep?.userSurveyId) {
                        await this.actions.userSurvey.deleteUserSurvey({
                            id: surveyStep?.userSurveyId,
                        });
                    }
                    const surveyAnswers = [];
                    const problems = [];
                    surveyStep?.items?.map((item) => {
                        if (item.value) {
                            const answer = {
                                question: { connect: { id: item?.id } },
                                metadata: {
                                    surveyOpportunityId: surveyStep?.id,
                                },
                            };
                            if (
                                item.type === "SLIDER" &&
                                item.value?.length > 0
                            ) {
                                const answers = [];
                                item.value?.map((v) =>
                                    answers.push({ answer: v?.toString() })
                                );
                                answer.answers = { create: answers };
                            } else if (
                                isArray(item.value) &&
                                item.value?.length > 0
                            ) {
                                const connect = [],
                                    create = [];
                                item.value?.map((v) => {
                                    if (v?.id) {
                                        connect.push({ id: v?.id });
                                    } else {
                                        if (
                                            item.category ===
                                            "Problems question"
                                        ) {
                                            create.push({
                                                answer: v?.answer,
                                                id: this.economies?.find(
                                                    (e) => e?.name === v?.answer
                                                )?.id,
                                            });
                                        } else {
                                            create.push({
                                                answer: v?.answer,
                                                metadata: {
                                                    ventureId:
                                                        this.survey
                                                            ?.targetCustomerUser
                                                            ?.venture?.id,
                                                    type: item.category?.includes(
                                                        "Solution"
                                                    )
                                                        ? "SOLUTION"
                                                        : "MOTIVATOR",
                                                    parent: item.category?.includes(
                                                        "Solution"
                                                    )
                                                        ? null
                                                        : surveyStep?.opportunityId,
                                                    child: item.category?.includes(
                                                        "Motivator"
                                                    )
                                                        ? null
                                                        : surveyStep?.opportunityId,
                                                    opportunityId: null,
                                                },
                                                question: {
                                                    connect: { id: item?.id },
                                                },
                                            });
                                        }

                                        // if (
                                        //     item.category ===
                                        //     "Problems question"
                                        // ) {
                                        //     problems.push({
                                        //         type: "PROBLEM",
                                        //         economy: {
                                        //             create: {
                                        //                 name: v?.answer,
                                        //                 problem: v?.answer,
                                        //             },
                                        //         },
                                        //     });
                                        // }
                                    }
                                });
                                answer.answers = { create, connect };
                            } else if (
                                isArray(item.value) &&
                                item.value?.length === 0
                            ) {
                            } else {
                                if (item?.value?.id && isNaN(item?.value?.id)) {
                                    answer.answers = {
                                        connect: [{ id: item?.value?.id }],
                                    };
                                } else {
                                    answer.answer =
                                        item.value.title || item.value;
                                }
                            }
                            surveyAnswers?.push(answer);
                        }
                    });
                    // if (problems.length > 0) {
                    // 	console.log(problems, 'problems ==================>')
                    // 	await this.actions.opportunity.saveOpportunity({
                    // 		where: {id: surveyStep?.opportunityId},
                    // 		data: {
                    // 			problems: {
                    // 				create: problems
                    // 			}
                    // 		}
                    // 	})
                    // }
                    var userSurveyParams = {
                        data: {
                            survey: { connect: { id: this.$route.params?.id } },
                            answers: {
                                create: surveyAnswers,
                            },
                            opportunity: {
                                connect: { id: surveyStep?.opportunityId },
                            },
                        },
                    };
                    // console.log(userSurveyParams, "userSurveyParams abc");

                    if (this.$route.query?.contactId) {
                        userSurveyParams.data.profile = {
                            connect: {
                                id: this.$route.query?.contactId,
                            },
                        };

                        if (this.profile?.user) {
                            userSurveyParams.data.user = {
                                connect: {
                                    id: this.profile?.user?.id,
                                },
                            };
                        }
                        const { saveProfile } =
                            await this.actions.profile.saveProfile({
                                where: { id: this.$route.query?.contactId },
                                data: { status: "SURVEY_COMPLETE" },
                            });
                        this.profile = saveProfile;
                    }
                    const { saveUserSurvey } =
                        await this.actions.userSurvey.saveUserSurvey(
                            userSurveyParams
                        );

                    console.log(saveUserSurvey, "saveUserSurvey");

                    this.steps[this.activeIndex].userSurveyId =
                        saveUserSurvey?.id;
                    await this.actions.milestone.calculateMilestone({
                        ventureId: this.survey?.targetCustomerUser?.venture?.id,
                    });
                }

                // DEMOGRAPHIC STEP
                if (step?.type === "DEMOGRAPHIC") {
                    let user = this.profile?.user;
                    const demographicSection = step;

                    if (!this.profile?.id) {
                        let email = demographicSection?.items?.find(
                            (item) =>
                                item.label ===
                                "What email can we use to contact you?"
                        )?.value;
                        if (!email) email = `${new Date().getTime()}@dscvry.io`;
                        const createAppUser = await this.actions.createAppUser({
                            email,
                            sendEmailVerification: false,
                        });

                        user = createAppUser?.user;
                    }

                    // HANDLE USER
                    if (user?.id) {
                        const items = demographicSection?.items?.filter(
                            (item) =>
                                item?.label !==
                                    "Would you be open to talking more about this topic?" &&
                                item?.label !==
                                    "What email can we use to contact you?"
                        );

                        const tagConnect = [],
                            tagCreate = [],
                            tagUpsert = [];

                        let genderDescribed = null,
                            ethnicityDescribed = null;

                        for (let j = 0; j < items.length; j++) {
                            const item = items[j];
                            const value = item.value;

                            if (isArray(value)) {
                                for (let i = 0; i < value.length; i++) {
                                    const val = value[i];

                                    if (val.id && isNaN(val?.id)) {
                                        tagConnect.push({ id: val?.id });
                                        if (
                                            val?.name === "Self describe" &&
                                            item?.dataType === "ETHNICITY"
                                        ) {
                                            ethnicityDescribed =
                                                item?.inputValue;
                                        }
                                    } else {
                                        if (item.dataType === "LOCATION") {
                                            const lct = val;
                                            const res =
                                                await this.actions.google.searchGooglePlaceById(
                                                    {
                                                        placeId: lct?.place_id,
                                                    }
                                                );
                                            console.log(res, "location detail");
                                            var city;
                                            var state;
                                            var country;
                                            res?.address_components?.map(
                                                (addressComponent) => {
                                                    if (
                                                        _.indexOf(
                                                            addressComponent.types,
                                                            "neighborhood"
                                                        ) > -1
                                                    )
                                                        city =
                                                            addressComponent[
                                                                "short_name"
                                                            ];
                                                    if (
                                                        _.indexOf(
                                                            addressComponent.types,
                                                            "locality"
                                                        ) > -1
                                                    )
                                                        city =
                                                            addressComponent[
                                                                "short_name"
                                                            ];
                                                    if (
                                                        _.indexOf(
                                                            addressComponent.types,
                                                            "administrative_area_level_1"
                                                        ) > -1
                                                    )
                                                        state =
                                                            addressComponent[
                                                                "short_name"
                                                            ];
                                                    if (
                                                        _.indexOf(
                                                            addressComponent.types,
                                                            "country"
                                                        ) > -1
                                                    )
                                                        country =
                                                            addressComponent[
                                                                "short_name"
                                                            ];
                                                }
                                            );

                                            if (city) {
                                                const item =
                                                    this.state.tag.tags.find(
                                                        (t) => t?.name === city
                                                    );
                                                const update = {
                                                    type: "LOCATION",
                                                    category: "CITY",
                                                    name: city,
                                                    nameLower:
                                                        city.toLowerCase(),
                                                    status: "NEW",
                                                    targetType: "ALL",
                                                };
                                                if (item?.id) {
                                                    tagUpsert.push({
                                                        where: {
                                                            id: item?.id,
                                                        },
                                                        update,
                                                        create: update,
                                                    });
                                                } else {
                                                    tagCreate.push(update);
                                                }
                                            }
                                            if (state) {
                                                const item =
                                                    this.state.tag.tags.find(
                                                        (t) => t?.name === state
                                                    );
                                                const update = {
                                                    type: "LOCATION",
                                                    category: "STATE",
                                                    name: state,
                                                    nameLower:
                                                        state.toLowerCase(),
                                                    status: "NEW",
                                                    targetType: "ALL",
                                                };
                                                if (item?.id) {
                                                    tagUpsert.push({
                                                        where: {
                                                            id: item?.id,
                                                        },
                                                        update,
                                                        create: update,
                                                    });
                                                } else {
                                                    tagCreate.push(update);
                                                }
                                            }
                                            if (country) {
                                                const item =
                                                    this.state.tag.tags.find(
                                                        (t) =>
                                                            t?.name === country
                                                    );
                                                const update = {
                                                    type: "LOCATION",
                                                    category: "COUNTRY",
                                                    name: country,
                                                    nameLower:
                                                        country.toLowerCase(),
                                                    status: "NEW",
                                                    targetType: "ALL",
                                                };
                                                if (item?.id) {
                                                    tagUpsert.push({
                                                        where: {
                                                            id: item?.id,
                                                        },
                                                        update,
                                                        create: update,
                                                    });
                                                } else {
                                                    tagCreate.push(update);
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (
                                    value?.name === "Self describe" &&
                                    item?.dataType === "GENDER"
                                ) {
                                    genderDescribed = item?.inputValue;
                                }
                                tagConnect.push({ id: value?.id });
                            }
                        }
                        const params = {
                            where: { id: user?.id },
                            data: {
                                tags: {
                                    connect: tagConnect,
                                    create: tagCreate,
                                    upsert: tagUpsert,
                                },
                                forceRegister: true,
                                genderDescribed,
                                ethnicityDescribed,
                            },
                        };

                        await this.actions.user.saveUser(params);
                        // done saving USER

                        // HANDLE PROFILE
                        const profile = {};
                        if (this.$route.query?.contactId) {
                            profile.connect = {
                                id: this.$route.query?.contactId,
                            };
                            await this.actions.profile.saveProfile({
                                where: { id: this.$route.query?.contactId },
                                data: {
                                    status:
                                        demographicSection?.items?.find(
                                            (item) =>
                                                item?.label ===
                                                "Would you be open to talking more about this topic?"
                                        )?.value?.title === "Yes"
                                            ? "OPEN_TO_TALK"
                                            : "SURVEY_COMPLETE",
                                    openToTalk:
                                        demographicSection?.items?.find(
                                            (item) =>
                                                item?.label ===
                                                "Would you be open to talking more about this topic?"
                                        )?.value?.title === "Yes",
                                },
                            });
                        } else {
                            const profileParams = {
                                user: { connect: { id: user?.id } },
                                targetCustomerUser: {
                                    connect: {
                                        id: this.survey?.targetCustomerUser?.id,
                                    },
                                },
                                venture: {
                                    connect: {
                                        id: this.survey?.targetCustomerUser
                                            ?.venture?.id,
                                    },
                                },
                                surveys: {
                                    connect: [{ id: this.survey?.id }],
                                },
                                openToTalk:
                                    demographicSection?.items?.find(
                                        (item) =>
                                            item?.label ===
                                            "Would you be open to talking more about this topic?"
                                    )?.value?.title === "Yes",
                                status:
                                    demographicSection?.items?.find(
                                        (item) =>
                                            item?.label ===
                                            "Would you be open to talking more about this topic?"
                                    )?.value?.title === "Yes"
                                        ? "OPEN_TO_TALK"
                                        : "SURVEY_COMPLETE",
                            };
                            const { saveProfile } =
                                await this.actions.profile.saveProfile({
                                    data: profileParams,
                                });
                            profile.connect = { id: saveProfile?.id };
                        }

                        const surveySteps = this.steps?.filter(
                            (s) => s.type === "SURVEY"
                        );

                        console.log(surveySteps, "surveySteps");

                        for (let i = 0; i < surveySteps?.length; i++) {
                            const surveyStep = surveySteps[i];
                            const { saveUserSurvey } =
                                await this.actions.userSurvey.saveUserSurvey({
                                    where: {
                                        id: surveyStep?.userSurveyId,
                                    },
                                    data: {
                                        user: {
                                            connect: { id: user?.id },
                                        },
                                        profile,
                                    },
                                });
                            await this.actions.milestone.calculateMilestone({
                                ventureId:
                                    this.survey?.targetCustomerUser?.venture
                                        ?.id,
                            });
                            const params = {
                                where: { id: surveyStep?.id },
                                data: {
                                    responses: {
                                        connect: [
                                            {
                                                id: saveUserSurvey?.id,
                                            },
                                        ],
                                    },
                                },
                            };

                            console.log(params, "saveSurveyOpportunity params");

                            const { saveSurveyOpportunity } =
                                await this.actions.surveyOpportunity.saveSurveyOpportunity(
                                    params
                                );
                            console.log(
                                saveSurveyOpportunity,
                                "saveSurveyOpportunity"
                            );
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isSaving = false;
            }
        },
        getDemographicText(tag) {
            if (tag?.category === "AGE") {
                return `Are you between the ages of ${tag?.name}`;
            } else if (tag?.category === "GENDER") {
                return `Are you a ${tag?.name}`;
            } else if (tag?.category === "ETHNICITY") {
                return `Is your ethnicity either ${tag.name}`;
            } else if (
                tag?.category === "DESCRIPTORS" ||
                tag?.category === "DESCRIPTORS"
            ) {
                return `Are you in ${tag.name}`;
            } else if (tag?.category === "INCOME") {
                let item = tag?.name?.split("(");
                if (item?.[1]) {
                    item = item?.[1]?.split(")")[0];
                }
                return `Is your income ${item}`;
            }
        },
        getFinalStep() {
            return {
                title:
                    this.previousSteps?.[this.previousSteps?.length - 1] === 1
                        ? "Thanks so much for your time!"
                        : this.survey?.closingHeading,
                description:
                    this.previousSteps?.[this.previousSteps?.length - 1] === 1
                        ? `We're focusing our research on a specific group of people right now but we really appreciate your willingness to help!<br><br><br>If you know any ${this.survey?.targetCustomerUser?.name} who would be willing to share their thoughts, we'd be grateful if you'd pass this link along.`
                        : this.survey?.closingDescription,
            };
        },
    },
    data() {
        const swiper = ref(null);
        const setSwiper = (e) => {
            swiper.value = e;
        };
        return {
            setSwiper,
            swiper,
            Controller,
            Virtual,
            isLoading: false,
            isSaving: false,
            steps: [],
            activeIndex: 0,
            slugify,
            user: null,
            email: null,
            survey: null,
            previousSteps: [],
            isArray,
            profile: null,
            economies: [],
            isValid: false,
        };
    },
    async mounted() {
        $(document).keydown(function (objEvent) {
            if (objEvent.keyCode == 9) {
                //tab pressed
                objEvent.preventDefault(); // stops its action
            }
        });
        this.isLoading = true;
        try {
            const surveys = await this.actions.survey.getSurveys({
                where: { id: this.$route.params?.id },
                getValues: true,
            });
            if (this.$route.query?.contactId) {
                const contacts = await this.actions.profile.getProfiles({
                    where: {
                        id: this.$route.query?.contactId,
                        isArchived_not: true,
                    },
                    getValues: true,
                });
                this.profile = contacts?.[0];
            }
            await this.actions.tag.getTags({
                all: true,
                where: { status: "APPROVED" },
            });
            const survey = surveys?.[0];
            console.log(survey, "survey");
            this.survey = survey;
            const steps = [];
            steps.push({
                title: this.survey?.welcomeHeading,
                description: this.survey?.welcomeDescription,
                type: "INTRO",
            });
            // TAG step
            if (!this.$route.query?.contactId) {
                const optional = {
                    title: "Tell us a little about yourself",
                    type: "TAG",
                    items: [],
                };
                survey?.targetCustomerUser?.targetCustomer?.tags?.map((tag) => {
                    optional.items?.push({
                        // label: this.getDemographicText(tag),
                        label: tag.confirmationQuestion,
                        type: "RADIO",
                        options: [
                            { id: 0, title: "Yes" },
                            { id: 1, title: "No" },
                        ],
                        value: null,
                    });
                });
                steps.push(optional);
            }
            await this.actions.economy.getEconomies({ all: true });
            this.state.economy.economies.map((o) =>
                this.economies?.push({
                    ...o,
                    name: upperFirst(o?.problem),
                    label: upperFirst(o?.problem),
                })
            );
            console.log(this.economies, "economies");
            // SURVEY STEPS
            survey.surveyOpportunities
                ?.sort((a, b) => (a?.sortOrder > b?.sortOrder ? 1 : -1))
                ?.map((o) => {
                    const step = {
                        title: o?.surveyQuestionGroup?.questions?.[0]?.question,
                        type: "SURVEY",
                        items: [],
                        id: o?.id,
                        opportunityId: o?.opportunity?.id,
                    };
                    o?.surveyQuestionGroup?.questions
                        ?.filter(
                            (q) =>
                                q?.category !== "Heading" &&
                                (o?.opportunity?.frequency &&
                                o?.opportunity?.frequencyUnit
                                    ? true
                                    : !q?.category?.includes("Frequency"))
                        )
                        ?.map((q) => {
                            console.log(q.category);
                            q?.answerOptions?.map((a) => {
                                // console.log(a, "a xyz");
                                const items = a.answer?.split(" ");
                                // a.id = q?.category?.includes("Problems")
                                //     ? a?.metadata?.economyId
                                //         ? a?.metadata?.economyId
                                //         : a?.id
                                //     : a?.id;
                                if (items?.[0]?.includes("#")) {
                                    items.shift();
                                    a.title = lowerFirst(items?.join(" "));
                                    a.answer = a.title;
                                } else {
                                    if (q?.category?.includes("Problem")) {
                                        a.title = a?.answer;
                                        a.problem = this.economies.find(
                                            (e) => e.name === a.answer
                                        )?.id;
                                        // a.id = this.economies.find(
                                        //     (e) => e.name === a.answer
                                        // )?.id;
                                    } else {
                                        a.title = a.answer;
                                    }
                                }
                            });
                            // console.log(q?.answerOptions, "q?.answerOptions");
                            if (
                                q?.category?.includes("Solutions") ||
                                q?.category?.includes("Motivators")
                            ) {
                                q.answerOptions = q.answerOptions?.filter(
                                    (a) => a?.metadata?.opportunityId
                                );
                            }
                            if (
                                !(
                                    (q?.category?.includes("Solutions") ||
                                        q?.category?.includes("Motivators")) &&
                                    q?.answerOptions?.length === 0
                                )
                            ) {
                                step.items?.push({
                                    label: q?.question,
                                    type: q?.inputType,
                                    options: q?.answerOptions,
                                    value:
                                        q?.inputType === "CHECKBOX"
                                            ? []
                                            : q?.inputType === "SLIDER"
                                            ? [0, 0]
                                            : null,
                                    id: q?.id,
                                    helpText: q?.helpBlurb,
                                    isAdd: true,
                                    category: q?.category,
                                    mark:
                                        q?.inputType === "SLIDER"
                                            ? q?.category?.includes("Desire")
                                                ? {
                                                      "-5": {
                                                          label: "Desperately don't want to",
                                                          tooltip:
                                                              "Desperately don't want to",
                                                      },
                                                      "-4": {
                                                          label: "",
                                                          tooltip:
                                                              "Really don't want to",
                                                      },
                                                      "-3": {
                                                          label: "",
                                                          tooltip:
                                                              "Don't want to",
                                                      },
                                                      "-2": {
                                                          label: "",
                                                          tooltip:
                                                              "Not interested",
                                                      },
                                                      "-1": {
                                                          label: "",
                                                          tooltip:
                                                              "Would rather not",
                                                      },
                                                      0: {
                                                          label: "Indifferent",
                                                          tooltip:
                                                              "Indifferent",
                                                      },
                                                      1: {
                                                          label: "",
                                                          tooltip:
                                                              "Barely interested",
                                                      },
                                                      2: {
                                                          label: "",
                                                          tooltip: "Interested",
                                                      },
                                                      3: {
                                                          label: "",
                                                          tooltip: "Want to",
                                                      },
                                                      4: {
                                                          label: "",
                                                          tooltip:
                                                              "Need to or have to",
                                                      },
                                                      5: {
                                                          label: "Desperately need to do",
                                                          tooltip:
                                                              "Desperately need to do",
                                                      },
                                                  }
                                                : {
                                                      "-5": {
                                                          label: "Worst thing ever",
                                                          tooltip:
                                                              "Worst thing ever",
                                                      },
                                                      "-4": {
                                                          label: "",
                                                          tooltip: "Hate it",
                                                      },
                                                      "-3": {
                                                          label: "",
                                                          tooltip:
                                                              "Really don't like it",
                                                      },
                                                      "-2": {
                                                          label: "",
                                                          tooltip: "Dislike it",
                                                      },
                                                      "-1": {
                                                          label: "",
                                                          tooltip:
                                                              "Could be better",
                                                      },
                                                      0: {
                                                          label: "Not bad, not good",
                                                          tooltip:
                                                              "Not bad, not good",
                                                      },
                                                      1: {
                                                          label: "",
                                                          tooltip: "It's ok",
                                                      },
                                                      2: {
                                                          label: "",
                                                          tooltip: "Like it",
                                                      },
                                                      3: {
                                                          label: "",
                                                          tooltip:
                                                              "Really like it",
                                                      },
                                                      4: {
                                                          label: "",
                                                          tooltip: "Love it",
                                                      },
                                                      5: {
                                                          label: "Favorite thing ever",
                                                          tooltip:
                                                              "Favorite thing ever",
                                                      },
                                                  }
                                            : null,
                                });
                            }
                        });
                    // console.log(step, "step");
                    steps.push(step);
                });
            // DEMOGRAPHIC STEP
            const demographic = {
                title: "Would you mind telling us a bit more about yourself?",
                type: "DEMOGRAPHIC",
                items: [],
            };
            const items = c.demographicQuestions.slice(0, 5);
            demographic.items = items;
            for (let i = 0; i < demographic.items.length; i++) {
                const tag = demographic.items[i];
                if (tag?.dataType === "AGE") {
                    tag.options = json(this.state.tag.tags)?.filter(
                        (t) => t.category === "AGE"
                    );
                }
                if (tag?.dataType === "GENDER") {
                    tag.options = json(this.state.tag.tags)
                        ?.filter((t) => t.category === "GENDER")
                        ?.sort((a, b) =>
                            a?.sortOrder > b?.sortOrder ? 1 : -1
                        );
                }
                if (tag?.dataType === "INCOME") {
                    tag.options = json(this.state.tag.tags)?.filter(
                        (t) => t.category === "INCOME"
                    );
                }
                if (tag?.dataType === "ETHNICITY") {
                    tag.options = json(this.state.tag.tags)?.filter(
                        (t) => t.category === "ETHNICITY"
                    );
                }
                if (tag?.dataType === "LOCATION") {
                }
                tag.options?.map((o) => (o.title = o?.name));
            }
            demographic.items?.push({
                label: "Would you be open to talking more about this topic?",
                type: "RADIO",
                options: [
                    { id: 0, title: "Yes" },
                    { id: 1, title: "No" },
                ],
                value: null,
            });
            if (!this.$route.query?.contactId) {
                demographic.items?.push({
                    label: "What email can we use to contact you?",
                    type: "EMAIL",
                    value: null,
                });
            }

            steps.push(demographic);
            // CONCLUSION STEP
            steps.push({
                url: this.survey.publicUrl,
                empty: {
                    description:
                        "Got your own idea and want to do your own research like this? Get started for free with DSCVRY!",
                    btnText: "Join Now!",
                    icon: "LightBulbIcon",
                },
                type: "CONCLUSION",
            });
            console.log(steps, "steps");
            this.steps = steps;

            // PREVIEW
            setTimeout(async () => {
                if (this.$route.query?.category) {
                    let index = 0;
                    if (this.$route.query?.category?.includes("_")) {
                        const splits = this.$route.query?.category?.split("_");
                        index = this.steps.findIndex(
                            (s) => s?.type === "SURVEY" && s?.id === splits[1]
                        );
                    } else {
                        index = this.steps.findIndex(
                            (s) => s?.type === this.$route.query?.category
                        );
                    }
                    if (index >= 0) {
                        this.activeIndex = index;
                        this.swiper.slideTo(this.activeIndex);
                    }
                }
                if (this.$route.query?.contactId) {
                    const userSurveys =
                        await this.actions.userSurvey.getUserSurveys({
                            where: {
                                profile: { id: this.$route.query?.contactId },
                                isArchived_not: true,
                            },
                            getValues: true,
                        });
                    if (userSurveys?.length > 0) {
                        this.activeIndex = this.steps?.length - 1;
                        this.swiper.slideTo(this.activeIndex);
                    }
                }
            }, 500);
        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    },
};
</script>
<style>
.custom-height {
    height: 100vh;
    top: 0px;
    top: env(safe-area-inset-top);
    bottom: 0px;
    bottom: env(safe-area-inset-bottom);
}
</style>
